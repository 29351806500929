<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Report Realisasi" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl Report *</label>
            <flat-pickr class="w-full" v-model="data.tgl_report"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Jumlah Klik *</label>
            <vs-input class="w-full" type="number" v-model="data.jumlah_klik"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Jumlah Tayang *</label>
            <vs-input class="w-full" type="number" v-model="data.jumlah_tayang"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">CTR (Click-through Rate) *</label>
            <vs-input class="w-full" v-model="data.ctr"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Sisa Saldo *</label>
            <v-money class="w-full" v-model="data.sisa_saldo"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
              <label class="ml-1 text-xs">Lampiran File Report</label>
              <vs-input class="w-full" type="file" v-model="data.fileTemp" @change="data.file = $event.target.files[0]"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan" />
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import PengajuanMediaRepository from '@/repositories/marketing/pengajuan-media-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'PengajuanMediaReportAdd',
  props: ['isActive', 'idPengajuanMedia'],
  components: {
    ValidationErrors,
    flatPickr,
    VMoney
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        tgl_report: moment().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.buildParams())
      PengajuanMediaRepository.createReport(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_pengajuan_media: this.idPengajuanMedia,
        tgl_report: this.data.tgl_report,
        jumlah_klik: this.data.jumlah_klik,
        jumlah_tayang: this.data.jumlah_tayang,
        ctr: this.data.ctr,
        sisa_saldo: this.data.sisa_saldo,
        keterangan: this.data.keterangan,
        file_report: this.data.file
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
